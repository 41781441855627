.mobile-nav {
	color: @body-bg;
}

.color-name {
	font-size: 16px;
}

.logo-img img {
	position: relative;
	bottom: 0;

	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-ms-transition: all 0.2s;
	-o-transition: all 0.2s;
}

.logo-img img:hover {
	bottom: 5px;
}

.index-contact p, .index-contact label {
	color: @header-contrast;
}

.top-tiles div {
	margin: 0;
	padding: 0;
}

.top-tiles .tile {
	overflow: hidden;
	position: relative;
	margin: 5px;
}

.top-tiles img {
	width: 100%;

	filter: blur(0px);
	-webkit-filter: blur(0px);
	-moz-filter: blur(0px);
	-ms-filter: blur(0px);
	-o-filter: blur(0px);


	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	-ms-transition: all 0.2s;
}

.top-tiles .tile:hover img {
	filter: blur(5px);
	-webkit-filter: blur(5px);
	-moz-filter: blur(5px);
	-ms-filter: blur(5px);
	-o-filter: blur(5px);
}

.top-tiles .tile:hover p {
	opacity: 1;
}

@media (min-width: 800px) and (max-width: 1000px) {
    .top-tiles h2 {
        font-size: 150%;
    }
    .top-tiles .tile:hover img {
        filter: blur(0);
        -webkit-filter: blur(0);
        -moz-filter: blur(0);
        -ms-filter: blur(0);
        -o-filter: blur(0);
        opacity: 0.7;
    }
}

@media screen and (max-width: 800px) {
    .top-tiles h2 {
        font-size: 120%;
    }
    .top-tiles .tile:hover img {
        filter: blur(0);
        -webkit-filter: blur(0);
        -moz-filter: blur(0);
        -ms-filter: blur(0);
        -o-filter: blur(0);
        opacity: 0.7;
    }
}

.top-tiles .tile:hover h2 {
	color: @headers-etc;
}

.top-tiles h2 {
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: rgba(0,0,0,0.5);
	margin: 0;
	color: white;
	padding: 10px;

	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	-ms-transition: all 0.2s;
}

.top-tiles p {
	position: absolute;
	background-color: rgba(0,0,0,0.5);
	color: #fff;
	padding: 10px;
	top: 0;
	opacity: 0;
	margin: 20px;

	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	-ms-transition: all 0.2s;
}

a {
	transition: all 0.25s;
	-webkit-transition: all 0.25s;
	-ms-transition: all 0.25s;
	-moz-transition: all 0.25s;
	-o-transition: all 0.25s;
}

.logo-contact {
	text-align: right;
	color: @header-contrast;
	font-size: 35px;
	font-weight: 700;
}

.logo-contact a {
	color: @header-contrast;
	text-decoration: none;
}

.logo-contact a:hover {
	color: @headers-etc;
}

@media screen and (max-width: 991px) {
    .logo-contact {
		text-align: center;
	}
}

.social-icon {
	position: relative;
	bottom: 0;

	transition: all 0.1s;
	-webkit-transition: all 0.1s;
	-ms-transition: all 0.1s;
	-moz-transition: all 0.1s;
	-o-transition: all 0.1s;
}

.social-icon:hover {
	bottom: 5px;
	box-shadow: 0 5px 0 @headers-etc;
}

.index-contact .submit {
	color: contrast(@brand-primary) !important;
	float: right;
}

.index-contact {
	background-color: @brand-primary;
	padding: 20px;
	border-radius: 5px;
	margin-top: 15px;
}

.site {
	background: @body-bg; //url("../img/plumbing-pipes.jpg");
    box-shadow: 0 0 20px -3px black;
}

.top {
	position: relative;
	width:100%;
	background-color: @header;
	padding-top: 20px;
}
.divider {
	position: relative;
	width:100%;
	height:10px;
	background-color: @divider;
	-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
           -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.divider:before,
.divider:after {
	content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.divider:after {
	right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}

.logo {
	position: relative;
}
.logo-img {
	z-index: 99;
}
.logo-text {
	padding-top: 22px;
	position: absolute;
	top: 0;
	left: 15px;
}
.slogan h3 {
	color: @body-bg;
}
.main-content {
	margin-top: 15px;
}
h1 {
	margin-bottom: 30px;
	}
.middle-content {
	width: 100%;
	margin: 40px 0;
	background: @brand-primary;

	h2, p, a {
		color: @headings-alt;
	}
}
.bottom-content {
	margin-bottom: 40px;
}

.bullet-bg {
	background-color: @brand-primary;
	border-radius: 10px;
	padding:10px;

	li {
		margin-left:30px;
		color: #fff;
	}
	}
.button-clear {
	width: 150px;
	height: 30px;
	border: 1px solid #fff;
	padding-top: 5px;
	margin: 0 auto 20px auto;
	border-radius: 10px;
}
.footer-bg {
	width: 100%;
	min-height: 100px;
	background-color: @footer;
	padding: 20px 0;
	margin-top: 40px;
	border-top: solid 5px @headers-etc;
	color: @footer-text;
}
.bottom-bar {
	width: 100%;
	height: 8px;
	background-color: @brand-primary;
}
.copyright {
	padding-top: 10px;
}
.top-img {
	padding-bottom: 20px;
}
.acceditation {
	img {
		padding-left: 20px;
	
	}
}

.contact {
	text-align: center;
}

@media screen and (min-width: @screen-sm-min) {
	.contact {
		text-align: right;
	}
}

#area-map,
#area-map-small {
	margin-top: 30px;
}
.subtitle-pge {
	border-bottom: 1px solid #CBCBCB;
	border-top: 1px solid #CBCBCB;
	line-height: 1.4;
	margin-bottom: 25px;
	padding-bottom: 25px;
	margin-top: 25px;
	padding-top: 25px;
	font-weight: 200;
	text-align: center;
}



.get {
    display:none;
}
.legend {
// float:right;
width:250px;
margin-top:140px;
}
.skills {
float:left;
clear:both;
width:100%;
}

.skills ul,
.skills li {
display:block;
list-style:none;
margin:0;
padding:0;
}

.skills li {
float:right;
clear:both;
padding:0 15px;
height:35px;
line-height:35px;
color:#fff;
margin-bottom:1px;
font-size:18px;
}

.skills .jq {
background:#97BE0D;
}

.skills .css {
background:#D84F5F;
}

.skills .html {
background:#88B8E6;
}

.skills .php {
background:#BEDBE9;
}

.skills .sql {
background:#EDEBEE;
}
